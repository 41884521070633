import React from 'react';
import classNames from 'classnames';

import { PopoverShell } from 'shared/components/popover/popover-shell.component';
import { Translation } from 'shared/components/translation/translation.component';

import './popover.component.scss';

interface Props {
  content: string | number | (() => JSX.Element | JSX.Element[]) | JSX.Element;
  trigger: string | number | (() => JSX.Element | JSX.Element[]) | JSX.Element;
  announceToScreenReader?: ResourceKey;
  triggerClassName?: string;
  mouseoverTrigger?: boolean;
  isNonButtonTrigger?: boolean; // TODO: Which wrapper element to use should probably be abstracted out of this class
  popoverClassName?: string;
  preferPlace?: 'above' | 'below';
  closeOnCustomClick?: boolean;
}

interface PopoverState {
  isOpen: boolean;
}

export class Popover extends React.Component<Props, PopoverState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.closeOnCustomClick !== this.props.closeOnCustomClick) {
      this.hidePopover();
    }
  }

  UNSAFE_componentWillReceiveProps(): void {
    this.setState({isOpen: false});
  }

  togglePopover = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  showPopover = () => {
    this.setState({isOpen: true});
  };
  hidePopover = () => {
    this.setState({isOpen: false});
  };

  render() {

    const {isOpen} = this.state;
    const popoverContentValue = typeof this.props.content === 'function' ? this.props.content() : this.props.content;
    const popoverTriggerValue = typeof this.props.trigger === 'function' ? this.props.trigger() : this.props.trigger;
    const {mouseoverTrigger} = this.props;

    {/*TODO: For accessibility, we should have popover-body element (and parent element) appear in DOM right below popover trigger. This way, users can reliably and easily use keyboard to access it.*/}
    return (
      <PopoverShell
        body={popoverContentValue}
        isOpen={isOpen}
        onOuterAction={this.togglePopover}
        tipSize={8}
        preferPlace={!!this.props.preferPlace ? this.props.preferPlace : 'below'}
        enterExitTransitionDurationMs={500}
        className={this.props.popoverClassName}
      >
        {this.props.isNonButtonTrigger
          ? <span
              aria-controls="popover-body"
              aria-expanded={isOpen}
              className={classNames(this.props.triggerClassName, 'popover-trigger')}
              onClick={!mouseoverTrigger ? this.togglePopover : null}
              onMouseOver={mouseoverTrigger && this.showPopover}
              onMouseOut={mouseoverTrigger && this.hidePopover}
            >
              <span className="visually-hidden">
                <Translation resource={this.props.announceToScreenReader}/>
              </span>
              {popoverTriggerValue}
            </span>
          : <button
              aria-controls="popover-body"
              aria-expanded={isOpen}
              className={classNames(this.props.triggerClassName, 'popover-trigger')}
              onClick={!mouseoverTrigger ? this.togglePopover : null}
              onMouseOver={mouseoverTrigger && this.showPopover}
              onMouseOut={mouseoverTrigger && this.hidePopover}
            >
              <span className="visually-hidden">
                <Translation resource={this.props.announceToScreenReader}/>
              </span>
              {popoverTriggerValue}
            </button>
        }
      </PopoverShell>
    );
  }
}
